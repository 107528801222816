<template>
  <div class="book">
    <web-header active-url="/publication" borderBottom></web-header>
    <div class="book-wrap" v-loading="loading">
      <div class="book-wrap-header">
        <div class="book-wrap-header-icon" v-if="info.litpic && info.litpic.img">
          <el-image :src="info.litpic.img" :preview-src-list="[info.litpic.img]"></el-image>
        </div>
        <div class="book-wrap-header-desc">
          <div class="title">{{ info.name }}</div>
          <div class="author">
            {{ info.author }}
          </div>
<!--          <div class="share">-->
<!--            <div class="share-label">分享到:</div>-->
<!--            <div class="share-box bdsharebuttonbox">-->
<!--              <a href="javascript:void(0);" class="share-item icon-wchat" data-cmd="weixin" title="分享到微信"></a>-->
<!--              <a href="javascript:void(0);" class="share-item icon-qq" onclick="return false;" data-cmd="qzone" title="分享到QQ空间"></a>-->
<!--              <a href="javascript:void(0);" class="share-item icon-smore" data-cmd="more" title="更多分享"></a>-->
<!--            </div>-->
<!--          </div>-->
          <div class="tip">
            <span>{{ info.publisher }}</span>
            <span v-if="info.publish_date">{{ info.publish_date }}</span>
          </div>
        </div>
      </div>
      <div class="book-wrap-blurb">
        <div class="book-wrap-blurb-title">作者简介</div>
        <div class="book-wrap-blurb-content" v-html="info.introduction"></div>
      </div>
      <div class="book-wrap-synopsis">
        <div class="book-wrap-synopsis-title">内容简介</div>
        <div class="book-wrap-synopsis-content" :style="{ display: !isMore ? 'block' : '-webkit-box' }" ref="synopsisRef" v-html="info.content"></div>
      </div>
      <div class="book-wrap-more" @click="showMore" v-if="isMore">点击查看更多</div>
    </div>
    <web-footer></web-footer>
    <web-right-menu></web-right-menu>
  </div>
</template>

<script>
import WebHeader from "@/components/webHeader";
import WebFooter from "@/components/webFooter";
import WebRightMenu from "@/components/webRightMenu";
import { bookDetail } from "@/api";
export default {
  name: 'Book',
  components: {
    WebHeader,
    WebFooter,
    WebRightMenu
  },
  data() {
    return {
      loading: false,
      // 是否有更多
      isMore: true,
      info: {}
    }
  },
  async mounted () {
    // await this.loadExternalScript("http://bdimg.share.baidu.com/static/api/js/share.js?v=89860593.js");
    // window._bd_share_config = {
    //   "common": {
    //     "bdSnsKey":{},
    //     "bdText":"",
    //     "bdMini":"2",
    //     "bdMiniList":false,
    //     "bdPic":"",
    //     "bdStyle":"0",
    //     "bdSize":"24"
    //   },
    //   "share":{}
    // }
  },
  updated() {
    this.$nextTick(()=>{
      if (this.$refs.synopsisRef.scrollHeight > this.$refs.synopsisRef.offsetHeight) {
        this.isMore = true;
      } else {
        this.isMore = false;
      }
    });
  },
  created() {
    if (this.$route.params.id && this.isNumber(this.$route.params.id)) {
      this.getDataDetail(this.$route.params.id);
    }
  },
  methods: {
    async getDataDetail(msgId) {
      this.loading = true;
      let res = await bookDetail(msgId);
      if (res && res.code == 200) {
        this.info = res.data;
      }
      this.loading = false;
    },
    // 查看更多
    showMore() {
      this.isMore = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.book {
  &-wrap {
    width: 50%;
    margin: 50px auto 80px auto;
    &-header {
      padding-top: 30px;
      display: flex;
      &-icon {
        line-height: 0;
        max-width: 260px;
      }
      &-desc {
        padding-top: 5%;
        padding-left: 50px;
        .title {
          font-size: 36px;
        }
        .author {
          font-size: 16px;
          margin-top: 20px;
          color: #999999;
          display: flex;
          align-items: center;
          span {
            margin-right: 10px;
            &:last-child {
              margin-right: 0;
            }
          }
        }
        .share {

        }
        .tip {
          font-size: 16px;
          margin-top: 10px;
          color: #999999;
          display: flex;
          align-items: center;
          span {
            &:after {
              content: "|";
              padding: 0 10px;
            }
            &:last-child {
              &:after {
                content: "";
              }
            }
          }
        }
      }
    }
    &-blurb {
      margin-top: 60px;
      &-title {
        font-size: 16px;
        font-family: "HarmonyOS_Bold";
      }
      &-content {
        margin-top: 20px;
        ::v-deep {
          p {
            font-size: 14px;
            display: block;
            line-height: 32px;
            color: #707070;
          }
        }
      }
    }
    &-synopsis {
      margin-top: 30px;
      &-title {
        font-size: 16px;
        font-family: "HarmonyOS_Bold";
      }
      &-content {
        margin-top: 20px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 10;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        ::v-deep {
          p {
            font-size: 14px;
            display: block;
            line-height: 32px;
            color: #707070;
          }
        }
      }
    }
    &-more {
      margin: 40px auto 30px auto;
      font-size: 12px;
      padding: 8px 30px;
      border: 1px solid #DCDFE6;
      color: #ed6f33;
      cursor: pointer;
      user-select: none;
      display: table;
      &:hover, &.active {
        border-color: #ed6f33;
      }
    }
  }
}
/* 针对笔记本屏幕或者缩放屏幕 */
@media screen and (min-width: 1024px) and (max-width: 1600px) {
  .book {
    &-wrap {
      width: 70%;
    }
  }
}
/* 针对平板 */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .book {
    &-wrap {
      width: 90%;
    }
  }
}
/* 针对手机 */
@media screen and (max-width: 767px) {
  .book {
    &-wrap {
      width: 90%;
      &-header {
        flex-direction: column;
        &-icon {
          max-width: 90%;
        }
        &-desc {
          padding-left: 0;
          text-align: center;
          .title {
            font-size: 28px;
          }
          .author, .tip {
            justify-content: center;
          }
        }
      }
    }
  }
}
</style>